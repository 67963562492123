.container {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.mainContainer {
	min-height: 100%;
	background-color: #F2F2F2;
}

.mainContainer>div {
	height: 100%;
	background-color: #F2F2F2;
}

.containerHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px 24px;
	border-bottom: 1px solid #C5C7D1;
	background-color: #fff;
}

.containerHeaderFiltro {
	display: flex;
	flex-direction: row;
	gap: 24px;
}

.titleHeader {
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	color: #1B1C1F;
}


.subtitleHeader {
	margin-bottom: 0;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #1B1C1F;
}

.containerBody {
	background-color: #F2F2F2;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(267px, 1fr));
	gap: 32px;
	padding: 24px 17px;
}

.cardDocumento {
	background-color: white;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	height: 303px;
}

.cardImg {
	height: 85px;
	background-size: cover;
}

.cardBody {
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 220px;
	justify-content: space-between;
}

.cardBody p {
	margin-bottom: 16px;
}

.containerAvatar {
	height: 70px;
	width: 70px;
	border: 4px solid #fff;
	position: absolute;
	top: 50px;
	background-size: cover;
	border-radius: 35px;
}

.headerCard {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.headerCard>h1 {
	margin-bottom: 0;
	color: #000000;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
}

.headerCard>p {
	margin-bottom: 0;
	color: #000000;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
}

.cardBody>p {
	margin-bottom: 0;
	color: #000000;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}